import { Box, Stack, Text } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import axios from "axios";
import { useEffect, useState } from "react";
import { getPic, getSender, getUserID } from "../config/ChatLogics";
import ChatLoading from "./ChatLoading";
import GroupChatModal from "./miscellaneous/GroupChatModal";
import { Button } from "@chakra-ui/react";
import { ChatState } from "../Context/ChatProvider";
import { authUrl } from "../config/url";
import { getCookie } from "../config/helper";
import { Image } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";
import React from "react";

const MyChats = ({ fetchAgain }) => {
  const history = useHistory();
  const { id } = useParams();
  const [loggedUser, setLoggedUser] = useState();
  const { selectedChat, setSelectedChat, user, chats, setChats } = ChatState();
  const toast = useToast();
  const fetchChats = async () => {
    // console.log(user._id);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${getCookie("jn_c")}`,
        },
      };

      const { data } = await axios.get(`${authUrl}/api/chat`, config);
      setChats(data);
    } catch (error) {
      toast({
        title: "Error Occured!",
        description: "Failure while loading chat messages",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  useEffect(() => {
    // let data = JSON.parse(localStorage.getItem("jn_c"));
    setLoggedUser({ user, _id: user });
    fetchChats();
    // eslint-disable-next-line
  }, [fetchAgain]);
  useEffect(() => {
    if (id && chats) {
      let chatIndex = chats.findIndex((c) => c.users.find((d) => d._id === id));
      let chat = chats[chatIndex];
      setSelectedChat(chat);
    }
  }, [id, chats]);

  return (
    <Box
      d={{ base: selectedChat ? "none" : "flex", md: "flex" }}
      flexDir="column"
      alignItems="center"
      p={3}
      bg="white"
      w={{ base: "100%", md: "31%" }}
      borderRadius="lg"
      borderWidth="1px"
    >
      <Box
        pb={3}
        px={3}
        fontSize={{ base: "18px", md: "20px", lg: "18px" }}
        d="flex"
        w="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        Contact List
        {/* <GroupChatModal>
          <Button
            d="flex"˝
            fontSize={{ base: "17px", md: "10px", lg: "17px" }}
            rightIcon={<AddIcon />}
          >
            New Group Chat
          </Button>
        </GroupChatModal> */}
      </Box>
      <Box
        d="flex"
        flexDir="column"
        p={3}
        bg="#F8F8F8"
        w="100%"
        h="100%"
        borderRadius="lg"
        overflowY="hidden"
      >
        {chats ? (
          <Stack overflowY="scroll">
            {chats.map((chat) => (
              <Box
                onClick={() => {
                  setSelectedChat(chat);
                  history.push("/chats/" + getUserID(loggedUser, chat.users));
                }}
                cursor="pointer"
                bg={selectedChat === chat ? "#267c93" : "#E8E8E8"}
                color={selectedChat === chat ? "white" : "black"}
                px={3}
                py={2}
                borderRadius="lg"
                key={chat._id}
                d="flex"
                alignItems="center"
                gap={5}
              >
                <Image
                  boxSize="32px"
                  objectFit="cover"
                  borderRadius="full"
                  src={getPic(loggedUser, chat.users)}
                  fallbackSrc="https://via.placeholder.com/150"
                  alt="Profile Pic"
                  style={{ marginRight: "10px" }}
                />
                <Text>
                  {!chat.isGroupChat
                    ? getSender(loggedUser, chat.users)
                    : chat.chatName}
                </Text>
                {/* {chat.latestMessage && (
                  <Text fontSize="xs">
                    <b>{chat.latestMessage.sender.name} : </b>
                    {chat.latestMessage.content.length > 50
                      ? chat.latestMessage.content.substring(0, 51) + "..."
                      : chat.latestMessage.content}
                  </Text>
                )} */}
              </Box>
            ))}
            {chats.length === 0 && <div>You don't have any connections</div>}
          </Stack>
        ) : (
          <ChatLoading />
        )}
      </Box>
    </Box>
  );
};

export default MyChats;
