import {
  Box,
  Center,
  Container,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import Login from "../components/Authentication/Login";
import { getCookie } from "../config/helper";
// import Signup from "../components/Authentication/Signup";
import React from "react";

function Homepage() {
  const history = useHistory();
  const userId = document?.currentScript?.getAttribute("userId")

  useEffect(() => {
    const user = getCookie("jn_c");
    if (userId) {
      history?.push(`/chats/${userId}`);
    } else if (user) history.push("/chats");
  }, [history]);

  return (
    <Container
      maxW="xl"
      centerContent
      d="flex"
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box bg="white" w="100%" p={4} borderRadius="lg" borderWidth="1px">
        <Tabs isFitted>
          <TabList mb="1em">
            <Tab>
              <Flex>
                <Center mr="8px">
                  <Text>Chat by </Text>
                </Center>
                <Center>
                  <Text fontSize="xl" fontWeight={"bold"}>
                    <a href="https://juznow.com">Juznow.com</a>
                  </Text>
                </Center>
              </Flex>
            </Tab>
            {/* <Tab>Sign Up</Tab> */}
          </TabList>
          <TabPanels>
            <TabPanel>
              <Login />
            </TabPanel>
            {/* <TabPanel>
              <Signup />
            </TabPanel> */}
          </TabPanels>
        </Tabs>
      </Box>
    </Container>
  );
}

export default Homepage;
