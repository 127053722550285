export function setCookie(cname, cvalue, expires_in = 3600) {
  let d = new Date();
  let expires = "";
  if (expires_in) {
    d.setTime(d.getTime() + expires_in);
    expires = "expires=" + d.toUTCString();
  }
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(name) {
  let value = "; " + document.cookie;
  let parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
}
export function deleteCookie(name) {
  setCookie(name, "", -1);
}

export function deleteAllCookies() {
  var c = document.cookie.split("; ");
  for (let i in c)
    document.cookie =
      /^[^=]+/.exec(c[i])[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
}
