import { Avatar } from "@chakra-ui/avatar";
import { Tooltip } from "@chakra-ui/tooltip";
import ScrollableFeed from "react-scrollable-feed";
import {
  isLastMessage,
  isSameSender,
  isSameSenderMargin,
  isSameUser,
} from "../config/ChatLogics";
import { ChatState } from "../Context/ChatProvider";
import React from "react";

const ScrollableChat = ({ messages }) => {
  const { user } = ChatState();

  return (
    <ScrollableFeed>
      {messages &&
        messages.map((m, i) => (
          <section>
            {m?.systemMessage && (
              <div className="systemMessage">
                {m?.systemMessage && m?.content}
              </div>
            )}
            {!m?.systemMessage && (
              <section>
                <div style={{ display: "flex" }} key={m._id}>
                  {(isSameSender(messages, m, i, user._id ?? user) ||
                    isLastMessage(messages, i, user._id ?? user)) && (
                    <Tooltip
                      label={m.sender.name}
                      placement="bottom-start"
                      hasArrow
                    >
                      <Avatar
                        mt="7px"
                        mr={1}
                        size="sm"
                        cursor="pointer"
                        name={m.sender.name}
                        src={m.sender.pic}
                      />
                    </Tooltip>
                  )}
                  <span
                    style={{
                      backgroundColor: `${
                        m.sender._id === user || m.sender._id === user
                          ? "#BEE3F8"
                          : "#B9F5D0"
                      }`,
                      marginLeft: isSameSenderMargin(
                        messages,
                        m,
                        i,
                        user._id ?? user
                      ),
                      marginTop: isSameUser(messages, m, i, user._id ?? user)
                        ? 3
                        : 10,
                      borderRadius: "20px",
                      padding: "5px 15px",
                      maxWidth: "75%",
                    }}
                  >
                    {m.content}
                  </span>
                </div>
              </section>
            )}
          </section>
        ))}
    </ScrollableFeed>
  );
};

export default ScrollableChat;
