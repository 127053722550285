import React, { useEffect } from "react";
import "./App.css";
import { Route } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import Chatpage from "./Pages/Chatpage";
import { getCookie, setCookie } from "./config/helper";

function App() {
  const token = document?.currentScript?.getAttribute("token") ?? null;
  const jn_c = getCookie("jn_c");
  if (token && !jn_c) {
    setCookie("jn_c", token, 12 * 60 * 60 * 24);
    setCookie("jn_w", true, 12 * 60 * 60 * 24);
  }
  return (
    <div className="App">
      <Route path="/" component={Homepage} exact />
      <Route path="/chats/:id?" component={Chatpage} />
      <Route path="/redirect" />
    </div>
  );
}

export default App;
